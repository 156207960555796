$primary: #2596be;
$primarylight: #028e37;
$primarylighter: #02bc6b;
$secondary: #1c6490;
$accent: #eb7b24;
$bg: #f6fafd;
$line: #e4e8eb;
$dark: #8d0000;
$bg1: #F0F4F9;
$bg2: #E6ECF1;
$lightPrimary:#2da1e1;
